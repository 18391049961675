import useServiceFactory from "../serviceFactory";

const apiUrl = `/solicitudes`;

const useCajaApi = () => {
    const service = useServiceFactory({ uri: process.env.REACT_APP_CAJA_API_URL });
    return {
        filtrar: async (params) => service.get(`${apiUrl}`, { params }),
        obtener: async (consecutivo) => service.get(`${apiUrl}/${consecutivo}`, { showLoading: true }),
        solicitar: async (formData) => service.post(`${apiUrl}`, formData),
        eliminarSolicitud: async (consecutivo) => service.delete(`${apiUrl}/${consecutivo}`,{ showSuccess: true }),
        guardarComprobantes: async (consecutivo, formData) => service.post(`${apiUrl}/${consecutivo}/comprobantes`, formData, { showSuccess: true }),
        obtenerCajas: async () => service.get(`/cajas`),
        obtenerAbono: async (cajaId, id) => service.get(`/cajas/${cajaId}/abonos/${id}`, { showLoading: true }),
        abonar: async (cajaId, formData) => service.post(`/cajas/${cajaId}/abonos`, formData,{ showSuccess: true }),
        eliminarAbono: async (cajaId, id) => service.delete(`/cajas/${cajaId}/abonos/${id}`,{ showSuccess: true }),
        excel: async (params) => service.get(`${apiUrl}/excel`,
            {
                params,
                showLoading: true,
                download: true,
                fileName: `caja_chica.xlsx`
            }),
        aprobar: async (formData) => service.post(`${apiUrl}/aprobar`, formData, { showLoading: false })
    }
};

export default useCajaApi;
