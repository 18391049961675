/* eslint-disable react-hooks/exhaustive-deps */
import React , { useState }  from "react";
import moment from "moment";
import * as numeral from "numeral";
import FieldCheckBox from "../../../shared/controls/fieldCheckBox";
import produce from "immer";
import FieldInputNumber from "../../../shared/controls/fieldInputNumber";
import Field from "../../../shared/forms/Field";
import Form from "../../../shared/forms/Form";
import FormClipboardImage from "../../../shared/forms/FormFields/FormClipboardImage";
const Gastos = ({ liquidacion, gastos, gastosCajaChica, onChange, esOperador, soloConsulta }) => {
    const [submitted] = useState(false);
    const actualizar = (value, propertyName, index) => {
        const newValue = produce(gastos, (draft) => {
            let row = draft[index];
            row[propertyName] = value;
            row.saldo = row.monto - row.comprobado;
            row.noComprobado = Math.max(0, row.saldo);
            row.saldo = row.saldo * -1;
            if(value.archivo !=null){
                row.archivo= value.archivo;
            }           
          
        });

        onChange(newValue, 'gastos');
    };

    const actualizarCajaChica = (value, propertyName, index) => {
        const newValue = produce(gastosCajaChica, (draft) => {
            let row = draft[index];
            row[propertyName] = value;
            row.saldo = row.monto - row.comprobado;
            row.noComprobado = Math.max(0, row.saldo);
            row.saldo = row.saldo * -1;
            if(value.archivo !=null){
                row.archivo= value.archivo;
            }           
          
        });

        onChange(newValue, 'gastosCajaChica');
    }

    const errors = null;
    return (
        <>
            <div className="form-row">
                <table className="table table-borderless">
                    <thead className="thead-light">
                        <tr>
                          
                            <th style={{ width: '20%' }}>TIPO</th>
                            <th style={{ width: '15%' }}>CONCEPTO</th>
                            <th style={{ width: '20%' }}>NOTA</th>
                            <th style={{ width: '25%' }}>DETALLE</th>
                            <th style={{ width: '10%' }}>NO COMPROBADO</th>
                            <th style={{ width: '10%' }}>COMPROBADO</th>
                            <th style={{ width: '20%' }}>COMPROBACION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {gastos?.map((item, index) => {
                            return (
                                <tr key={index}>
                                      {!soloConsulta && liquidacion.aplazarMovimientos  ?
                                        <td>
                                            <FieldCheckBox name="seleccionado" value={item.seleccionado} label={item.tipoNombre} colMd={12}
                                                onChange={(value, pname) => actualizar(value, pname, index)}
                                            />
                                        </td>
                                        :
                                        <td>{item.tipoNombre}</td>
                                    }
                                    <td>{item.concepto}</td>
                                    <td>{item.nota}</td>
                                    <td className="p-d-flex p-flex-column">
                                        <span>Fecha de solicitud: {moment(item.fecha).format('DD/MM/YYYY hh:mm a')}</span>
                                        <div>
                                            Solicitante: <span className="bold">{item.solicitante}</span>
                                            <br />
                                            Pagado en: <span className="bold">Movimientos</span>
                                        </div>
                                    </td>
                                    <td>{numeral(item.noComprobado).format('$0,0.00')}</td>
                                    <td>{(!item.comprobanteRequerido) ?
                                        <>{numeral(item.comprobado).format('$0,0.00')}</>
                                        :
                                        <>
                                            {!soloConsulta && liquidacion.aplazarMovimientos && esOperador && item.porComprobar ?
                                                <FieldInputNumber required name="comprobado" fractionDigits={2} errors={errors}
                                                    value={item?.comprobado} disabled={soloConsulta}
                                                    onChange={(value, pname) => actualizar(value, pname, index)} />
                                                :
                                                <>{numeral(item.comprobado).format('$0,0.00')}</>
                                            }
                                        </>
                                    }
                                    </td>
                                    <td>
                                        {(item.comprobanteRequerido && !soloConsulta && liquidacion.aplazarMovimientos && esOperador && item.porComprobar) &&
                                              <Form columns={1} values={item} width={'100%'}  submitted={submitted}
                                              className={`form-container ${submitted ? 'submitted' : ''}`}
                                              onChange={(value, propertyName) => actualizar(value, propertyName, index)}>
                                                    <Field field={"archivo"} required={item.comprobanteRequerido} render={FormClipboardImage} />
                                                </Form>
                                        }
                                    </td>
                                </tr>
                            )
                        })}

                        {gastosCajaChica?.map((item, index) => {
                            return (
                                <tr key={index}>
                                    {!soloConsulta && liquidacion.aplazarMovimientos && esOperador ?
                                        <td>
                                            <FieldCheckBox name="seleccionado" value={item.seleccionado} label={item.tipo} colMd={12}
                                                onChange={(value, pname) => actualizarCajaChica(value, pname, index)} />
                                        </td>
                                        :
                                        <td>{item.tipo}</td>
                                    }
                                    <td>{item.concepto}</td>
                                    <td>{item.nota}</td>
                                    <td className="p-d-flex p-flex-column">
                                        <span>Fecha de solicitud: {moment(item.fecha).format('DD/MM/YYYY hh:mm a')}</span>
                                        <div>
                                            Solicitante: <span className="bold">{item.solicitante}</span>
                                            <br />
                                            Pagado en: <span className="bold">Caja Chica (Gastos Operativos)</span>
                                            <br />
                                            Solcitud Caja: <span className="bold">{item.noSolicitud}</span>
                                        </div>
                                    </td>
                                    <td>{numeral(item.noComprobado).format('$0,0.00')}</td>
                                    <td>{(!item.comprobanteRequerido) ?
                                        <>{numeral(item.comprobado).format('$0,0.00')}</>
                                        :
                                        <>
                                            {!soloConsulta && liquidacion.aplazarMovimientos && esOperador && item.porComprobar ?
                                                <FieldInputNumber required name="comprobado" fractionDigits={2} errors={errors}
                                                    value={item?.comprobado} disabled={soloConsulta}
                                                    onChange={(value, pname) => actualizarCajaChica(value, pname, index)} />
                                                :
                                                <>{numeral(item.comprobado).format('$0,0.00')}</>
                                            }
                                        </>
                                    }
                                    </td>
                                    <td>
                                        {(item.comprobanteUrlComprobado !=null) &&
                                         
                                            <Form columns={1} values={item} width={'100%'}  submitted={submitted}
                                            className={`form-container ${submitted ? 'submitted' : ''}`}                     
                                             onChange={(value, propertyName) => actualizarCajaChica(value, propertyName, index)}>
                                                <Field  field={"comprobanteUrlComprobado"} required={item.comprobanteRequerido} render={FormClipboardImage} />
                                            </Form>                                        
                 

                                       
                                       }
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
};


export default Gastos;
