/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import _ from "lodash";
import Field from "../../../shared/forms/Field";
import ExGrid from "../../../shared/Grid/ExGrid";
import ExColumn from "../../../shared/Grid/ExColumn";
import FormDropDown from "../../../shared/forms/FormFields/FormDropDown";
import FormInputNumber from "../../../shared/forms/FormFields/FormInputNumber";
import AddButton from "../../../shared/Grid/Buttons/AddButton";
import DeleteButton from "../../../shared/Grid/Buttons/DeleteButton";
import FormTextArea from "../../../shared/forms/FormFields/FormTextArea";
import * as numeral from "numeral";
import Form from "../../../shared/forms/Form";
import FormCalendar from "../../../shared/forms/FormFields/FormCalendar";
import { fechaMaximaSolicitud, fechaMinimaSolicitud } from "../CajaConstantes";
import FormAutocomplete from "../../../shared/forms/FormFields/FormAutocomplete";
import FormClipboardImage from "../../../shared/forms/FormFields/FormClipboardImage";
import FormMultiSelect from "../../../shared/forms/FormFields/FormMultiSelect";
const metodosDePago = [{ id: 12, nombre: 'Efectivo' }, { id: 11, nombre: 'Transferencia' }, { id: 13, nombre: 'Tarjeta' },
{ id: 14, nombre: 'Deposito en sucursal' }, { id: 15, nombre: 'Tienda de convenencia' }];

const SolicitudGastos = ({ value, onChange, onValidate, cajaId, soloLectura }) => {

    const disabledGastos = value.porAceptar ? false :  null;

    return (
        <>
            <Form
                columns={2}
                values={value}
                onChange={onChange}
                onValidate={onValidate}
                formDisabled={value?.disabled || soloLectura}
            >
                <h5 style={{ gridColumnEnd: 'span 2' }}>
                    Datos de solicitud
                </h5>

                <Field label="Fecha" field={"fecha"} render={FormCalendar} required={true} minDate={fechaMinimaSolicitud} maxDate={fechaMaximaSolicitud} />

                <Field label="Solicitante" field={"solicitanteId"} source="cajachica/jefes" params={{ todasLasEmpresas: true }}
                    render={FormDropDown} required={true} />

                <Field label="Destinatario" field={"destinatario"} source="cajachica/empleados" params={{ todasLasEmpresas: true }}
                    render={FormAutocomplete} required={true} />


                <h5 style={{ gridColumnEnd: 'span 2' }}>
                    Gastos
                </h5>
                <Field field={"gastos"} cols={2} render={({ value, onParentChange, ...props }) =>
                    <ExGrid {...props} data={value}
                        onChange={(data) => onParentChange({ gastos: data, monto: _.sumBy(data, 'monto') })}
                        getEntityId={(row) => row._uniqueUid}
                    >
                        <ExColumn field="tipoGastoId" title="Tipo" source="cajachica/tipoGastos" required={true}
                            cellRenderer={({ row, onRowChanged, ...props }) =>
                                <FormDropDown {...props}
                                              params={{ cajaId: cajaId }}
                                              onChange={(id) => onRowChanged({ tipoGastoId: id, conceptoId: null })}
                                              disabled={row.disabled ?? false}
                                />
                            } />

                        <ExColumn field="conceptoId" title="Concepto" required={true}
                            cellRenderer={({ row, onRowChanged, ...props }) =>
                                <FormDropDown {...props} params={{ tipoGastoId: row.tipoGastoId, cajaId: cajaId }}
                                            source="cajachica/conceptos"
                                            disabled={row.disabled ?? false}
                                            onChange={(id, concepto) => {
                                                onRowChanged({ conceptoId: id, tipoGastoId: concepto.tipoGastoId })
                                            }}
                                />
                            } />

                        <ExColumn field="monto" title="Monto" source="empleados" mode="decimal" minFractionDigits={2} maxFractionDigits={2} required={true}
                            cellRenderer={ ({row,...props})=> <FormInputNumber {...props} disabled={row.disabled ?? false} />} />

                        <ExColumn field={"command"}
                                  headerRenderer={({onAdd, formDisabled})=> <AddButton onAdd={onAdd} formDisabled={disabledGastos??formDisabled }/>}
                                  cellRenderer={({row, onDelete})=> <DeleteButton onDelete={onDelete} formDisabled={row.disabled}/> }
                                  width={'50px'} />
                    </ExGrid>
                } />
                <Field label="Método de Pago" field={"tipoMetodoPago"} cols={2} maxSelectedLabels={2} options={metodosDePago} required={true}
                       disabled={disabledGastos}
                        render={FormMultiSelect} />

                <Field label="Monto Solicitado" field={"monto"}
                    render={({ value }) => <span className="bold">{numeral(value).format('$0,0.00')}</span>} />

                <Field label="Comentario" field={"comentario"} cols={2} rows={4} required={true} disabled={disabledGastos}
                    render={FormTextArea} />

                <Field label="Evidencia Solicitud" field={"evidenciaSolicitud"} required={true}
                    render={FormClipboardImage} />

                <Field label="Evidencia de pago" field={"evidenciaPago"} required={true} disabled={disabledGastos}
                    render={FormClipboardImage} />
            </Form>
        </>
    )
}

export default SolicitudGastos;
