/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { Button } from "primereact/button";
import Viajes from "./secciones/viajes";
import Anticipos from "./secciones/anticipos";
import Vacaciones from "./secciones/vacaciones";
import Impuestos from "./secciones/impuestos";
import CajaAhorro from "./secciones/cajaahorro";
import PensionAlimenticia from "./secciones/pensionAlimenticia"
import * as numeral from "numeral";
import FieldCheckBox from "../../shared/controls/fieldCheckBox";
import moment from "moment";
import _ from "lodash";
import FieldTextArea from "../../shared/controls/fieldTextArea";
import FieldTextBox from "../../shared/controls/fieldTextBox";
import useApiLiquidaciones from "../../api/administracion/useApiLiquidaciones";
import Rendimiento from "./secciones/rendimiento";
import { produce } from "immer";
import constantes from "../../administracion/constantes";
import Gastos from "./secciones/gastos";
import Compensaciones from "./secciones/compensaciones";
import Descuentos from "./secciones/descuentos";
import PrestamoCajaAhorro from "./secciones/prestamoCajaAhorro";
import Movimientos from "./secciones/movimientos";
import { serialize } from 'object-to-formdata';
import constantesc from "../../catalogos/constantes";
import ReadOnlyProvider from "../../shared/forms/ReadOnlyProvider";

const LiquidacionDetalle = ({ liquidacionActual, esOperador, onClose, soloLectura, esquemaPago }) => {

    const [liquidacion, setLiquidacion] = useState({});
    const api = useApiLiquidaciones();
    const errors = null;
    const tipo = constantes.tiposMovimiento;
    const staff =  constantesc.esquemaPago.staff;
    const oficina = constantesc.esquemaPago.oficina;
    const Operativa= constantesc.esquemaPago.liquidacion;
    useEffect(() => {
        setLiquidacion(liquidacionActual);
    }, [liquidacionActual]);

    const actualizar = (value, propertyName) => {
        console.log('total')
        const newValue = produce(liquidacion, (draft) => {
            draft[propertyName] = value;
            
            draft.totalComision = _.sumBy(
                draft.viajes.filter((e) => e.seleccionado),
                "comision"
            );
            if (propertyName === "viajes") {
                draft.viajes.forEach((item) => {
                    draft.anticipos.forEach((itemant) => {
                        itemant.activo = item.seleccionado;
                    });
                });
            }

            draft.totalAnticipos = _.sumBy(
                draft.anticipos.filter((e) => e.activo),
                "saldo"
            );
            draft.totalOtros = _.sumBy(
                draft.movimientos.filter(
                    (e) =>
                        e.seleccionado &&
                        e.tipo !== tipo.bono &&
                        e.tipo !== tipo.prestamo &&
                        e.tipo !== tipo.tiempoExtra
                ),
                "monto"
            );
            draft.totalGastosCajaChica = _.sumBy(
                draft.gastosCajaChica.filter((g) => g.seleccionado),
                "saldo"
            );
            if (propertyName === "gastos") {
                if (esOperador){
                    draft.totalGastos = _.sumBy(
                        draft.gastos.filter((g) => g.seleccionado),
                        "saldo"
                    );
                }else{
                    draft.totalGastos = _.sumBy(
                        draft.gastos.filter((g) => g.seleccionado),
                        "comprobado"
                    );
                }
               
            }
            draft.totalCompensaciones = _.sumBy(
                draft.compensaciones.filter((c) => c.seleccionado),
                "monto"
            );
            draft.totalDescuentos = _.sumBy(
                draft.descuentos.filter((d) => d.seleccionado),
                "monto"
            );
            draft.totalCajaAhorro = _.sumBy(
                draft.cajaAhorro.filter((d) => d.seleccionado),
                "ahorro"
            );
            draft.totalPrestamoCajaAhorro = _.sumBy(
                draft.descuentosPrestamoCajaAhorro.filter((d) => d.seleccionado),
                "monto"
            );
            if (draft.semanaImpuestos && draft.semanaImpuestos.length > 0) {
                draft.totalImpuestos =
                    -1 *
                    _.sumBy(
                        draft.semanaImpuestos.filter((e) => e.seleccionado),
                        "totalSemana"
                    );
                let imss = _.sumBy(
                    draft.semanaImpuestos.filter((e) => e.seleccionado),
                    "montoIMSS"
                );
                let ispt = _.sumBy(
                    draft.semanaImpuestos.filter((e) => e.seleccionado),
                    "montoISPT"
                );
                draft.impuestosPension = -1 * (imss + ispt);
            } else {
                draft.impuestosPension =
                    _.sumBy(
                        draft.impuestos.filter((e) => e.impuesto !== "INFONAVIT"),
                        "monto"
                    ) * -1;
            }
            draft.totalAjuste = _.sumBy(
                draft.rendimientos.filter((c) => c.seleccionado),
                "ajuste"
            );
            let subtotal;

            if (draft.sumarSueldoSemanalTransfer) {
                subtotal = draft.totalComision + draft.sueldoBase;
            } else {
                if (!esOperador) {
                    subtotal = draft.sueldoBase;
                } else {
                    subtotal =
                        draft.complementarSueldoSemanal && draft.totalComision < draft.sueldoBase
                            ? draft.sueldoBase
                            : draft.totalComision;
                }
            }

            draft.total =
                subtotal +
                draft.totalPrestamos +
                draft.totalAnticipos +
                draft.totalAjuste +
                draft.totalBonos +
                draft.totalVacaciones +
                draft.totalOtros +
                draft.totalImpuestos +
                draft.totalCajaAhorro +
                draft.totalPrestamoCajaAhorro +
                draft.totalPensionAlimenticia +
                draft.totalPrimaVacacional +
                draft.totalGastos +
                draft.totalCompensaciones +
                draft.totalDescuentos +
                draft.totalGastosCajaChica;
        });

        setLiquidacion(newValue);
    };

    const liquidar = async () => {
        const cajaChica = liquidacion?.gastosCajaChica
            .filter((g) => g.seleccionado && g.comprobanteRequerido && g.comprobado > 0 && g.porComprobar)
            .map((m) => {
                return { id: m.movimientoId, comprobante: m.archivo, monto: m.comprobado };
            });
        const movimiento = liquidacion?.gastos
            .filter((g) => g.seleccionado && g.comprobanteRequerido && g.porComprobar)
            .map((m) => {
                return { id: m.movimientoId, comprobante: m.archivo, saldo: m.saldo };
            });

        // if (cajaChica.filter(c => c.comprobante === undefined).length > 0 || movimiento.filter(c => c.comprobante === undefined).length > 0) {
        //     alerts.aviso('Es necesario agregar comprobante a gastos comprobados.');
        //     return false;
        // }

        await api.liquidar(liquidacion);

        if (await subirComprobantes(cajaChica, movimiento)) {
            onClose();
        }
    };

    async function subirComprobantes(cajaChica, movimiento) {
        if (cajaChica.length > 0) {
            let comprobacionGastos = {
                comprobantes: cajaChica,
            };

            const formData = serialize(comprobacionGastos, { indices: true, dotsForObjectNotation: true });
            await api.comprobar(formData);
        }

        if (movimiento.length > 0) {
            let comprobacionGastos = {
                comprobantes: movimiento,
            };

            const formData = serialize(comprobacionGastos, { indices: true, dotsForObjectNotation: true });
            await api.comprobarGastos(formData);
        }

        return true;
    }

    return (
        <>
         
         <div className="form-container full-height" style={{ paddingLeft: "5px" }}>
                <div className="adm-liquidacion-detalle">
                    <div className="form-row">
                        <h6 className="col-8">
                            <b>Empleado: </b> {liquidacion?.empleado?.nombre}
                        </h6>
                        <p>
                            <b>Fecha Ultima Liquidación: </b>
                            {moment(liquidacion?.empleado?.ultimaFechaLiquidacion).format("DD/MM/YYYY")}
                        </p>
                    </div>
                    <ReadOnlyProvider defaultReadOnly={soloLectura}>
                        <TabView activeIndex={0}>
                            <TabPanel
                                header="VIAJES" headerClassName="custom-tab-header"
                                headerStyle={esOperador ? { display: "inline" } : { display: "none" }}
                                rightIcon={
                                    errors?.datosGenerales || errors?.asignacion ? "pi pi-exclamation-circle" : ""
                                }
                            >
                                <Viajes viajes={liquidacion?.viajes} onChange={actualizar} />
                            </TabPanel>
                            <TabPanel header="COMPENSACIONES" headerClassName="custom-tab-large-header">
                                <Compensaciones 
                                    liquidacion={liquidacion}
                                    compensaciones={liquidacion?.compensaciones}
                                    onChange={actualizar}
                                    esOperador={liquidacion?.empleado?.esOperador}
                                />
                            </TabPanel>
                            <TabPanel header="DESCUENTOS" headerClassName="custom-tab-small-header">
                                <Descuentos
                                    liquidacion={liquidacion}
                                    descuentos={liquidacion?.descuentos}
                                    onChange={actualizar}
                                    esOperador={liquidacion?.empleado?.esOperador}
                                />
                            </TabPanel>
                            <TabPanel header="GASTOS" headerClassName="custom-tab-header">
                                <Gastos
                                    liquidacion={liquidacion}
                                    gastos={liquidacion?.gastos}
                                    gastosCajaChica={liquidacion?.gastosCajaChica}
                                    onChange={actualizar}
                                    esOperador={liquidacion?.empleado?.esOperador}
                                />
                            </TabPanel>
                            <TabPanel
                                header="RENDIMIENTO" headerClassName="custom-tab-small-header"
                                headerStyle={esOperador ? { display: "inline" } : { display: "none" }}
                                rightIcon={
                                    errors?.datosPersonales || errors?.familiares ? "pi pi-exclamation-circle" : ""
                                }
                            >
                                <Rendimiento
                                    liquidacion={liquidacion}
                                    rendimientos={liquidacion?.rendimientos}
                                    onChange={actualizar}
                                    esOperador={liquidacion?.empleado?.esOperador}
                                />
                            </TabPanel>
                            <TabPanel
                                header="ANTICIPOS" headerClassName="custom-tab-header"
                                headerStyle={esOperador ? { display: "inline" } : { display: "none" }}
                                rightIcon={errors?.seguridadSocial ? "pi pi-exclamation-circle" : ""}
                            >
                                <Anticipos anticipos={liquidacion?.anticipos} onChange={actualizar} />
                            </TabPanel>
                            <TabPanel header="OTROS" headerClassName="custom-tab-header">
                                <Movimientos
                                    liquidacion={liquidacion}
                                    movimientos={liquidacion?.movimientos}
                                    onChange={actualizar}
                                    esOperador={liquidacion?.empleado?.esOperador}
                                />
                            </TabPanel>
                            <TabPanel header="VACACIONES" headerClassName="custom-tab-small-header">
                                <Vacaciones vacaciones={liquidacion?.vacaciones} />
                            </TabPanel>
                            <TabPanel
                                header="IMPUESTOS"
                                rightIcon={
                                    errors?.antidoping || errors?.actasAdministrativas ? "pi pi-exclamation-circle" : ""
                                }
                            >
                                <Impuestos
                                    impuestos={liquidacion?.impuestos}
                                    semanaImpuestos={liquidacion?.semanaImpuestos}
                                    onChange={actualizar}
                                />
                            </TabPanel>
                            <TabPanel header="CAJA AHORRO" headerClassName="custom-tab-large-header">
                                <CajaAhorro
                                    liquidacion={liquidacion}
                                    ahorros={liquidacion?.cajaAhorro}
                                    onChange={actualizar}
                                    esOperador={liquidacion?.empleado?.esOperador}
                                />
                            </TabPanel>
                            <TabPanel header="PRÉSTAMO AHORRO" headerClassName="custom-tab-large-header">
                                <PrestamoCajaAhorro
                                    liquidacion={liquidacion}
                                    descuentosPrestamoCajaAhorro={liquidacion?.descuentosPrestamoCajaAhorro}
                                    onChange={actualizar}
                                    esOperador={liquidacion?.empleado?.esOperador}
                                />
                            </TabPanel>
                            <TabPanel header="PENSIÓNALIMENTICIA" headerClassName="custom-tab-large-header">
                                <PensionAlimenticia
                                    liquidacion={liquidacion}
                                    pensionAlimenticia={liquidacion?.pensionAlimenticia}
                                    onChange={actualizar}
                                    esOperador={liquidacion?.empleado?.esOperador}
                                />
                            </TabPanel>
                        </TabView>
                        <div className="form-row adm-resumen-viajes">
                            <div className="complementos">
                                {esOperador && (
                                    <table>
                                        <tr>
                                            <td>
                                                <FieldCheckBox
                                                    name="complementarSueldoSemanal"
                                                    disabled={soloLectura}
                                                    value={liquidacion.complementarSueldoSemanal}
                                                    onChange={actualizar}
                                                />
                                            </td>
                                            <td>
                                                <span>Complementar Sueldo Semanal</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <FieldCheckBox
                                                    name="sumarSueldoSemanalTransfer"
                                                    disabled={soloLectura}
                                                    value={liquidacion.sumarSueldoSemanalTransfer}
                                                    onChange={actualizar}
                                                />
                                            </td>
                                            <td>
                                                <span>Sumar Sueldo Semanal Transfer</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <FieldCheckBox
                                                    name="comentarioObligatorio"
                                                    disabled={soloLectura}
                                                    value={liquidacion.comentarioObligatorio}
                                                    onChange={actualizar}
                                                />
                                            </td>
                                            <td>
                                                <span>Liquidar sin rendimiento</span>
                                            </td>
                                        </tr>
                                    </table>
                                )}
                                <br />
                                {esOperador && liquidacion.comentarioObligatorio && (
                                    <>
                                        <span>
                                            {" "}
                                            <b> Comentarios: </b>{" "}
                                        </span>
                                        <FieldTextArea
                                            name="comentarios"
                                            value={liquidacion.comentarios}
                                            rows={2}
                                            onChange={actualizar}
                                        />
                                        <span>
                                            {" "}
                                            <b> Usuario: </b>{" "}
                                        </span>
                                    </>
                                )}
                                <FieldTextBox
                                    name="usuarioNombre"
                                    colMd={12}
                                    disabled
                                    value={liquidacion.usuarioNombre}
                                    onChange={actualizar}
                                />
                            </div>
                            <div className="totales">
                                <table>
                                    <tbody>
                                    {((liquidacion.tieneStaff && esquemaPago === staff)  || (liquidacion.tieneAdministrativa  && esquemaPago=== oficina)|| (liquidacion.tieneOperativa && esquemaPago === Operativa)) && (
                                            <tr>
                                                <td>Sueldo Base</td>
                                                <td>{numeral(liquidacion.sueldoBase).format("$0,0.00")}</td>
                                            </tr>
                                         )}
                                        {esOperador && (
                                            <tr>
                                                <td>Comisión</td>
                                                <td>{numeral(liquidacion.totalComision).format("$0,0.00")}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td>Préstamos</td>
                                            <td>{numeral(liquidacion.totalPrestamos).format("$0,0.00")}</td>
                                        </tr>
                                        <tr>
                                            <td>Caja Ahorro</td>
                                            <td>{numeral(liquidacion.totalCajaAhorro).format("$0,0.00")}</td>
                                        </tr>
                                        {esOperador && (
                                            <>
                                                <tr>
                                                    <td>Anticipos</td>
                                                    <td>{numeral(liquidacion.totalAnticipos).format("$0,0.00")}</td>
                                                </tr>
                                                <tr>
                                                    <td>Compensaciones</td>
                                                    <td>
                                                        {numeral(liquidacion.totalCompensaciones).format("$0,0.00")}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Descuentos</td>
                                                    <td>{numeral(liquidacion.totalDescuentos).format("$0,0.00")}</td>
                                                </tr>
                                                <tr>
                                                    <td>Gastos</td>
                                                    <td>
                                                        {numeral(
                                                            liquidacion.totalGastos + liquidacion.totalGastosCajaChica
                                                        ).format("$0,0.00")}
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                        {!esOperador && (
                                            <>
                                                <tr>
                                                    <td>Gastos</td>
                                                    <td>{numeral(liquidacion.totalGastos).format("$0,0.00")}</td>
                                                </tr>
                                                <tr>
                                                    <td>Descuentos</td>
                                                    <td>{numeral(liquidacion.totalDescuentos).format("$0,0.00")}</td>
                                                </tr>
                                            </>
                                        )}
                                        <tr>
                                            <td>Préstamo Caja Ahorro</td>
                                            <td>{numeral(liquidacion.totalPrestamoCajaAhorro).format("$0,0.00")}</td>
                                        </tr>
                                        {esOperador && (
                                            <tr>
                                                <td>Ajuste</td>
                                                <td>{numeral(liquidacion.totalAjuste).format("$0,0.00")}</td>
                                            </tr>
                                        )}
                                        <tr>
                                            <td>Bonos</td>
                                            <td>{numeral(liquidacion.totalBonos).format("$0,0.00")}</td>
                                        </tr>
                                        <tr>
                                            <td>Total Vacaciones</td>
                                            <td>{numeral(liquidacion.totalVacaciones).format("$0,0.00")}</td>
                                        </tr>
                                        <tr>
                                            <td>Vacaciones Trabajadas</td>
                                            <td>{numeral(liquidacion.totalVacacionesTrabajadas).format("$0,0.00")}</td>
                                        </tr>
                                        <tr>
                                            <td>Vacaciones No Trabajadas</td>
                                            <td>{numeral(liquidacion.totalVacacionesNoTrabajadas).format("$0,0.00")}</td>
                                        </tr>                                      
                                        <tr>
                                            <td>Prima Vacacional</td>
                                            <td>{numeral(liquidacion.totalPrimaVacacional).format("$0,0.00")}</td>
                                        </tr>                                      

                                        <tr>
                                            <td>Otros</td>
                                            <td>{numeral(liquidacion.totalOtros).format("$0,0.00")}</td>
                                        </tr>

                                        <tr>
                                            <td>Impuestos</td>
                                            <td>{numeral(liquidacion.totalImpuestos).format("$0,0.00")}</td>
                                        </tr>
                                        <tr>
                                            <td>Pension Alimenticia</td>
                                            <td>{numeral(liquidacion.totalPensionAlimenticia).format("$0,0.00")}</td>
                                        </tr>
                                        {((liquidacion.tieneStaff && esquemaPago === staff)  || (liquidacion.tieneAdministrativa  && esquemaPago=== oficina)  || (liquidacion.tieneOperativa && esquemaPago === Operativa)) && (
                                            <tr>
                                                <td>TOTAL</td>
                                                <td>{numeral(liquidacion.total).format("$0,0.00")}</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ReadOnlyProvider>
                </div>
            </div>
            <div className="form-footer">
                <Button label="Cancelar" type="button" className="p-button-outlined" onClick={onClose} />
                {liquidacion.permisoLiquidar && !soloLectura && (
                    <Button label="Liquidar" type="button" onClick={liquidar} />
                )}
            </div>
        </>
    );
};

export default LiquidacionDetalle;
